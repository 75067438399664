import Vue from "vue";

export default {
  createPayment(data) {
    return Vue.http.post(`${url}/payment/insert`, {
      data,
    });
  },
  log(data) {
    return Vue.http.post(`${url}/payment/logs`, data);
  },
};
