<template>
  <section id="payment-product">
    <div class="alignment">
      <h1>Pagamento</h1>

      <div v-if="!responseMessage" class="columns">
        <div class="column">
          <div class="columns is-multiline">
            <!-- Dados pessoais do usuario -->
            <div class="column is-full">
              <div class="e10-card">
                <div class="level">
                  <div v-if="!isMobile" class="level-left">
                    <b-icon pack="fas" icon="user"></b-icon>
                  </div>
                  <div class="level-item">
                    <div class="aligment-info">
                      <h2>Dados Pessoais</h2>
                      <div class="data">
                        <div class="field-user-info">
                          <span>Nome</span>
                          <p>{{ `${userInfo.nome} ${userInfo.sobrenome}` }}</p>
                        </div>
                        <div class="field-user-info">
                          <span>Email</span>
                          <p>{{ userInfo.email }}</p>
                        </div>
                        <div class="field-user-info">
                          <span>Telefone</span>
                          <p>
                            {{ userInfo.telefone ? userInfo.telefone : "" }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="level-right">
                    <b-button
                      type="is-pigeon"
                      icon-right="edit"
                      icon-pack="fas"
                      :expanded="isMobile"
                      :size="isMobile ? 'is-small' : ''"
                      @click="handleEditUser"
                      >Editar</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Opcoes de pagamento -->
            <div class="column is-full">
              <div class="e10-card card-light">
                <div class="e10-card-header">
                  <div>
                    <h2>Formas de Pagamento</h2>
                    <p>Selecione a melhor forma de pagamento para você</p>
                  </div>
                </div>
                <div class="e10-card-content">
                  <!-- Integracoes para pagamento -->
                  <div v-if="!isFree">
                    <div
                      v-for="integration of integrations"
                      :key="`integration-${integration.id}`"
                      class="integration-item"
                    >
                      <div class="level">
                        <div class="level-left">
                          <div class="integration-check">
                            <b-icon
                              v-if="
                                paymentData.integration === integration.slug
                              "
                              pack="fas"
                              icon="check"
                              type="is-success"
                            ></b-icon>
                          </div>
                          <img
                            :src="integration.image"
                            :alt="integration.name"
                          />
                        </div>
                        <div class="level-right">
                          <div class="buttons">
                            <div
                              class="type-button"
                              :selected="paymentData.type === type.slug"
                              :disabled="isInvalidForWallet(type.slug)"
                              v-for="type of integration.types"
                              :key="`type-${type.id}`"
                              @click="
                                handleSelectIntegration(integration, type)
                              "
                            >
                              {{ type.name }}
                              <small v-if="type.slug === 'carteira'"
                                >R$ {{ wallet.saldo_wallet }}</small
                              >
                              <b-icon
                                pack="fas"
                                :icon="type.icon"
                                size="is-small"
                              ></b-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          Plano Gratuito, não precisa escolher uma forma de
                          pagamento!
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Opcoes bancarias -->
                  <div v-if="showBankContent && !isFree" class="bank-container">
                    <p class="bank-title">Selecione um banco para continuar:</p>
                    <div class="columns is-multiline is-mobile">
                      <div
                        v-for="bank of banks"
                        :key="bank.id"
                        class="column centralize"
                        :class="{ 'is-narrow': !isMobile, 'is-half': isMobile }"
                      >
                        <div
                          class="bank-item"
                          :selected="selectedBankId === bank.id"
                          @click="handleSelectBank(bank)"
                        >
                          <img
                            :src="`/banks/banco-${bank.id}.jpg`"
                            :alt="bank.name"
                          />
                          <b-icon
                            v-if="selectedBankId === bank.id"
                            pack="fas"
                            type="is-success"
                            icon="check-circle"
                          ></b-icon>
                          <p>{{ bank.name }}</p>
                        </div>
                      </div>

                      <div class="column is-full">
                        <div class="columns">
                          <div class="column">
                            <b-field
                              label="Agência de Origem"
                              message="Informe sua agência bancária"
                            >
                              <b-input
                                v-model="bankAgency"
                                required
                                maxlength="8"
                              ></b-input>
                            </b-field>
                          </div>
                          <div class="column">
                            <b-field
                              label="Conta de Origem"
                              message="Informe sua conta bancária"
                            >
                              <b-input
                                v-model="bankAccount"
                                required
                                maxlength="25"
                              ></b-input>
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Planos -->
        <div class="column is-one-third">
          <div class="e10-card">
            <div class="e10-card-header">
              <div>
                <h2>Planos</h2>
                <p>Selecione um plano abaixo</p>
              </div>
            </div>
            <div class="e10-card-content">
              <label class="cupom-label" for="cupom-input"
                >Possui um cupom? Insira abaixo</label
              >
              <b-field>
                <b-input
                  id="cupom-input"
                  size="is-small"
                  placeholder="Cupom"
                  v-model="cupom"
                  expanded
                ></b-input>
                <b-button
                  size="is-small"
                  type="is-primary"
                  icon-pack="fas"
                  icon-right="check"
                  :disabled="cupom === ''"
                  @click="loadPlans"
                  >Validar</b-button
                >
              </b-field>

              <b-loading
                :active.sync="isLoadingPlans"
                :is-full-page="false"
              ></b-loading>
              <div v-if="!isLoadingPlans">
                <div
                  v-for="plan of plans"
                  :key="plan.codigo"
                  class="card-plan"
                  :selected="selectedPlanId === plan.codigo"
                  @click="handleSelectPlan(plan.codigo)"
                >
                  <div class="level is-mobile">
                    <div class="level-left">
                      <div>
                        <b-tag
                          v-if="selectedPlanId === plan.codigo"
                          type="is-primary"
                          size="is-small"
                          style="margin-bottom: 0.75em"
                          >Selecionado</b-tag
                        >
                        <p>{{ plan.periodo }}</p>
                      </div>
                      <span class="description">{{ plan.descricao }}</span>
                    </div>
                    <div class="level-right">
                      <div v-if="plan.comDesconto">
                        <small
                          >De:
                          {{ plan.valor.toFixed(2).replace(".", ",") }}</small
                        >
                        <span
                          >R$
                          {{
                            plan.cupom.valor.toFixed(2).replace(".", ",")
                          }}</span
                        >
                        <b-tag size="is-small" type="is-pigeon"
                          >{{ plan.cupom.desconto }}%</b-tag
                        >
                      </div>
                      <div v-else>
                        <span
                          >R$
                          {{ plan.valor.toFixed(2).replace(".", ",") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="margin-top: 1em">
            <b-button
              expanded
              :disabled="!showConfirmPayment"
              type="is-success"
              size="is-medium"
              icon-pack="fas"
              icon-right="check"
              @click="confirmPayment"
            >
              <b>Continuar Pagamento</b>
            </b-button>
          </div>
        </div>
      </div>

      <div v-else class="columns">
        <div class="column">
          <div class="e10-card">
            <div class="e10-card-header">
              <div>
                <h2>Finalizar Pagamento</h2>
              </div>
            </div>
            <div class="e10-card-content">
              <DownTimer
                :starttime="timerStart"
                :endtime="timerEnd"
                :trans="{
                  hours: 'Hora(s)',
                  minutes: 'Minuto(s)',
                  seconds: 'Segundo(s)',
                  running: 'Tempo estimado para efetuar o pagamento.',
                  expired:
                    'Realize uma nova transação para efetuar o pagamento.',
                  status: {
                    expired: 'Transação Expirada!',
                  },
                }"
              ></DownTimer>

              <div class="pix-container">
                <b-button
                  v-if="responseMessage.qrcode"
                  icon-pack="fas"
                  icon-right="copy"
                  type="is-pigeon"
                  @click="copyQrCode()"
                  >Copiar o código do QR code
                </b-button>
                <b-input
                  type="hidden"
                  id="export_csv_file"
                  :value="export_file"
                ></b-input>
              </div>
              <div v-if="responseMessage.success">
                <div v-html="responseMessage.success"></div>
              </div>
              <div v-else-if="responseMessage.error">
                <div v-html="responseMessage.error"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubscriptionsService from "../../services/subscriptionsService";
import ModalMeusDados from "@/apps/Auth/modals/MeusDados";
import DownTimer from "@/components/helpers/DownTimer";

export default {
  name: "Payment",
  components: {
    ModalMeusDados,
    DownTimer,
  },
  data() {
    return {
      plans: [],
      selectedPlanId: null,
      selectedBankId: null,
      cupom: this.$route.query.cupom ? this.$route.query.cupom : "",
      integrations: [],
      paymentData: {
        integration: "",
        type: "",
      },
      banks: [],
      isLoadingPlans: false,
      bankAgency: "",
      bankAccount: "",
      timerStart: null,
      timerEnd: null,
      responseMessage: null,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "wallet"]),
    isMobile() {
      return this.$mq === "sm";
    },
    influencerId() {
      return this.$route.query.influencer ? this.$route.query.influencer : 25;
    },
    showBankContent() {
      const { integration, type } = this.paymentData;
      return (
        ["paymee"].includes(integration) &&
        ["transferencia", "deposito"].includes(type)
      );
    },
    showConfirmPayment() {
      return this.validatePaymentData({ notNotify: true });
    },
    isFree() {
      if (!this.selectedPlanId) return false;

      const planSelected = this.getPlanSelected();
      return planSelected.valor === 0;
    },
  },
  methods: {
    ...mapActions([
      "getPlans",
      "getPaymentIntegration",
      "getWallet",
      "getUser",
    ]),
    loadPlans() {
      this.isLoadingPlans = true;
      const cupom = this.cupom.toUpperCase();
      this.getPlans({
        cpf: this.userInfo.cpf,
        email: this.userInfo.email,
        cupom,
        influencer: this.influencerId,
      })
        .then((plansResponse) => {
          const isValidPlans = plansResponse.length > 0;

          if (!isValidPlans) {
            this.validCupom();
          } else {
            this.plans = plansResponse.filter((p) => !p.bloqueado);
            this.validSelectedPlan();
          }
        })
        .finally(() => {
          this.isLoadingPlans = false;
        });
    },
    loadIntegrations() {
      this.getPaymentIntegration({
        operation: "compra",
        product: "Planos",
      }).then((response) => {
        this.integrations = response;
      });
    },
    handleEditUser() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalMeusDados,
        hasModalCard: false,
        fullScreen: this.$mq === "sm",
        canCancel: false,
        props: {
          fluid: this.$mq === "sm",
        },
        events: {
          error: () => {},
          close: () => {},
          updated: () => {
            this.getUser();
          },
        },
      });
    },
    validCupom() {
      if (this.cupom !== "") {
        this.$toasted.show("Cupom Inválido", {
          position: "top-center",
          duration: 3000,
          type: "error",
          className: "toasted-class",
        });
      }
    },
    validSelectedPlan() {
      const queryPlanId = this.$route.query.plan
        ? this.$route.query.plan
        : null;

      if (queryPlanId) {
        if (this.plans.find((p) => p.codigo == queryPlanId)) {
          this.selectedPlanId = parseInt(queryPlanId);
        }
      }
    },
    handleSelectPlan(id) {
      if (id === this.selectedPlanId) return;

      this.$buefy.dialog.confirm({
        title: "Alterar Plano",
        message: "Realmente deseja selecionar outro plano?",
        confirmText: "Escolher outro plano",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          this.selectedPlanId = id;
        },
      });
    },
    handleSelectIntegration(integration, type) {
      if (this.isInvalidForWallet(type.slug)) return;

      this.paymentData.integration = integration.slug;
      this.paymentData.type = type.slug;

      if (integration.slug === "paymee") {
        if (type.slug === "transferencia") {
          this.handleBankTransfer();
        } else if (type.slug === "deposito") {
          this.handleBankDeposit();
        }
      }
    },
    handleBankTransfer() {
      this.banks = [
        { id: "001", name: "Banco do Brasil" },
        { id: "033", name: "Banco Santander" },
        { id: "077", name: "Banco Inter" },
        { id: "104", name: "Banco CEF" },
        { id: "212", name: "Banco Original" },
        { id: "218", name: "Banco BS2" },
        { id: "237", name: "Banco Bradesco" },
        { id: "341", name: "Banco Itaú" },
      ];
    },
    handleBankDeposit() {
      this.banks = [
        { id: "033", name: "Banco Santander" },
        { id: "341", name: "Banco Itaú" },
      ];
    },
    handleSelectBank(bank) {
      this.selectedBankId = bank.id;
    },
    validateDataBankTransfer({ notNotify }) {
      const isValidData = this.bankAgency && this.bankAccount;

      if (this.selectedBankId !== null && isValidData) {
        return true;
      } else {
        if (!notNotify) this.notify("Insira as informações bancárias!");
        return false;
      }
    },
    notify(message) {
      this.$buefy.snackbar.open({
        message,
        type: "is-danger",
        pauseOnHover: true,
        position: "is-bottom-right",
      });
    },
    validatePaymentData({ notNotify }) {
      if (!this.selectedPlanId) {
        if (!notNotify) this.notify("Selecione um plano!");
        return false;
      }

      if (this.paymentData.integration && this.paymentData.type) {
        if (this.showBankContent) {
          return this.validateDataBankTransfer({ notNotify });
        }
        return true;
      } else {
        if (!notNotify) this.notify("Selecione uma forma de pagamento!");
        return false;
      }
    },
    confirmPayment() {
      if (!this.validatePaymentData({ notNotify: false })) return;

      const reference = new Date().getTime();

      const payment = {
        reference,
        details: this.getPlanSelected(),
        user: { ...this.userInfo },
        product: "Planos",
        integration: this.paymentData.integration,
        type: this.paymentData.type,
        bank: "",
        account: "",
        branch: "",
      };

      if (this.showBankContent) {
        const bank = this.banks.find((b) => b.id === this.selectedBankId);
        payment.bank = bank;
        payment.branch = this.bankAgency;
        payment.account = this.bankAccount;
      }

      this.createPayment(payment);
    },
    createPayment(data) {
      this.isLoadingPlans = true;
      SubscriptionsService.createPayment(data)
        .then(this.handleResponsePayment)
        .catch((err) => {
          this.saveLog(err.message);
        })
        .finally(() => {
          this.isLoadingPlans = false;
        });
    },
    saveLog(description) {
      SubscriptionsService.log({
        id_pssa: this.userInfo.id,
        integration: this.paymentData.integration,
        type: this.paymentData.type,
        description,
      });
    },
    handleResponsePayment(response) {
      if (response.ok) {
        if (response.body.data) {
          const responseData = response.body.data;
          const redirectUrl = responseData.url;

          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else if (responseData.success) {
            this.responseMessage = responseData;
            if (this.paymentData.type !== "carteira") {
              this.makeDataToTimer();
            }
          }
        } else {
          this.saveLog("Error_payment_register, response.body.data não existe");
        }
      } else {
        this.saveLog(
          "Error_payment, erro inesperado ao efetuar o processo de pagamento"
        );
      }
    },
    makeDataToTimer() {
      const timeEnd = new Date();
      const timeStart = new Date();
      timeEnd.setHours(new Date().getHours() + 24);

      this.timerStart = timeStart.toString();
      this.timerEnd = timeEnd.toString();
    },

    getPlanSelected() {
      return this.plans.find((p) => p.codigo === this.selectedPlanId);
    },
    isInvalidForWallet(typeSlug) {
      if (typeSlug !== "carteira") return false;

      if (!this.selectedPlanId) return false;

      const planSelected = this.getPlanSelected();

      return this.wallet.saldo_wallet <= planSelected;
    },
    async copyQrCode() {
      const export_file = this.responseMessage.qrcode;

      let testingCodeToCopy = document.querySelector("#export_csv_file");
      testingCodeToCopy.value = export_file;
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$toasted.show("Código Copiado!", {
            position: "top-center",
            duration: 2000,
            type: "success",
            className: "toasted-class",
          });
        } else {
          this.$toasted.show("Não foi possível copiar, tente novamente!", {
            position: "top-center",
            duration: 2000,
            type: "error",
            className: "toasted-class",
          });
        }
      } catch (err) {
        this.$toasted.show("Não foi possível copiar, tente novamente!", {
          position: "top-center",
          duration: 2000,
          type: "error",
          className: "toasted-class",
        });
      }

      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
  async created() {
    Promise.all([
      this.loadIntegrations(),
      this.loadPlans(),
      this.getWallet(this.userInfo.id),
    ]);
  },
};
</script>

<style lang="scss">
#payment-product {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto;

  .input,
  select {
    background-color: #3f435e !important;
    border-color: #7285a5 !important;
  }

  .alignment {
    width: 70%;
    margin: 0 auto;
    height: 100%;
    padding-top: 2em;

    @media (min-width: 1140px) {
      min-width: 1140px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    h1 {
      width: 100%;
      text-align: left;
      color: #00f0f8;
      font-size: 2em;
      margin-bottom: 1em;
      text-transform: uppercase;
      font-weight: 600;
    }

    .aligment-info {
      width: 100%;
      height: 100%;
      padding-left: 2em;

      @media (max-width: 768px) {
        padding-left: 0;

        .data {
          flex-direction: column;
        }
      }

      h2 {
        font-weight: lighter;
        color: #7076a2;
        font-size: 0.8em;
        margin-bottom: 0.5em;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .data {
        display: flex;

        .field-user-info {
          display: flex;
          flex-direction: column;
          margin-right: 1em;

          &:last-child {
            margin-right: 0;
          }

          span {
            font-size: 0.75em;
            color: #7076a2;
          }
        }
      }
    }

    .bank-container {
      margin-top: 1em;
      width: 100%;
      padding: 1em 0.5em;
      border-radius: 7px;
      background-color: #444966;
      transition: all 0.2s ease-in-out;

      .bank-title {
        margin-bottom: 1em;
      }

      .bank-item {
        position: relative;
        width: 110px;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        .icon {
          position: absolute;
          background-color: white;
          border-radius: 50%;
          right: 25px;
          top: 20px;
        }

        &[selected] {
          img {
            border-radius: 7px;
            border-color: white;
          }
          p {
            color: white !important;
          }
        }

        img {
          margin-bottom: 0.5em;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          overflow: hidden;
          border: 2px solid transparent;
        }
        p {
          text-align: center;
        }

        &:hover {
          img {
            transition: all 0.2s ease-in-out;
            border-color: white;
            border-radius: 7px;
          }
          p {
            transition: all 0.2s ease-in-out;
            color: white !important;
          }
        }
      }
    }

    .card-plan {
      padding: 1em;
      border: 1px solid #7285a5;
      border-radius: 7px;
      margin-bottom: 1em;

      &[selected] {
        background-color: #303348;
      }

      .level {
        .level-left {
          flex-direction: column;
          align-items: flex-start;
        }

        .level-right {
          & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          small {
            font-size: 0.8em;
            opacity: 0.6;
            text-decoration: line-through;
          }
        }
      }

      .description {
        font-size: 0.8em;
      }

      &:hover {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border-color: #00f0f8;
        background-color: #303348;
      }
    }

    .integration-item {
      padding: 0.75em;
      border-bottom: 1px solid #3e425c;

      @media (max-width: 768px) {
        .level-left {
          display: flex;
          align-items: center;
        }

        .level-left + .level-right {
          margin-top: 0.5em;
        }
      }

      img {
        height: 30px;
      }

      .integration-check {
        width: 20px;
        margin-right: 1em;
      }

      .level-right {
        align-items: center;
      }

      .type-button {
        padding: 0.5em 0.75em;
        margin: 0.5em;
        border-radius: 4px;
        color: white;
        background-color: #3f435e;
        display: flex;
        align-items: center;
        font-size: 0.9em;

        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          cursor: pointer;
          transition: all 0.2s ease;
          background-color: rgb(51, 56, 82);
        }

        small {
          margin-left: 0.5em;
          font-size: 0.85em;
        }

        &[selected] {
          background-color: #00f0f8;
          color: black;

          .icon {
            color: black;
          }
        }

        &[disabled] {
          cursor: not-allowed;
          background-color: #56596a;
          color: rgb(201, 201, 201);
        }

        .icon {
          margin-left: 0.5em;
          color: white;
        }
      }
    }

    .card-light {
      background-color: #494e6b !important;
      border-color: #444966 !important;

      p {
        color: #9da6df !important;
      }
    }
    .e10-card {
      border-radius: 7px;
      background-color: #363a54;
      border: 1px solid #363a54;
      transition: all 0.2s ease-in-out;
      padding: 1em;
      width: 100%;

      .e10-card-header {
        padding: 1em 0;
        border-bottom: 1px solid #3e425c;
        display: flex;
        justify-content: space-between;

        h2 {
          font-size: 1.2em;
          color: white;
        }
        p {
          font-size: 0.9em;
          color: #787faa;
        }
      }

      .e10-card-content {
        padding-top: 1em;

        .cupom-label {
          font-size: 0.9em;
          color: #787faa;
        }
      }

      @media (max-width: 768px) {
        padding: 0.75em;
      }
    }
  }
}
</style>
