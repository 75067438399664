<template>
  <section id="chosing-auth">
    <div v-if="view === 'choice'" class="alignment">
      <p>
        Você precisa se autenticar ou se cadastrar para continuar, siga os
        passos abaixo!
      </p>
      <h1>Você possui uma conta no ESCALA 10?</h1>
      <div class="alignment-cards">
        <div class="card-option" @click="handleSelect('signIn')">
          <div first class="card-option-image"></div>

          <div class="block-text">
            <h2>Sim. Quero renovar pra mitar!</h2>
          </div>
        </div>
        <div class="card-option" @click="handleSelect('signUp')">
          <div second class="card-option-image"></div>
          <div class="block-text">
            <h2>Não. Mas quero me cadastrar!</h2>
          </div>
        </div>
      </div>
    </div>
    <div v-if="view === 'signIn'" class="alignment">
      <div class="module">
        <div class="container-login">
          <Authentication
            ref="auth"
            :display-default-logo="false"
            @success="handlerLogin"
            @showRegister="handleSelect('signUp')"
          ></Authentication>
          <b-button type="is-pigeon" @click="handleSelect('choice')"
            >Voltar</b-button
          >
        </div>
      </div>
    </div>
    <div v-else-if="view === 'signUp'" class="alignment">
      <div class="module">
        <div class="container-login">
          <Registration
            ref="register"
            :display-default-logo="false"
            @success="handlerRegister"
            @showLogin="handleSelect('choice')"
          ></Registration>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Authentication from "@/apps/Auth/components/Authentication.vue";
import Registration from "@/apps/Auth/components/Registration.vue";

export default {
  name: "ChoosingAuth",
  components: {
    Authentication,
    Registration,
  },
  data() {
    return {
      view: "choice",
    };
  },
  methods: {
    handleSelect(chosenView) {
      this.view = chosenView;
    },
    handlerLogin() {
      this.$toasted.success("Autenticação concluída!", {
        position: "top-center",
        duration: 3000,
      });
      this.$emit("auth-done");
    },
    handlerRegister() {
      this.$toasted.success("Usuário criado!", {
        position: "top-center",
        duration: 3000,
      });
      this.$emit("auth-done");
    },
  },
};
</script>

<style lang="scss">
#chosing-auth {
  width: 100%;
  height: 100%;

  .alignment {
    width: 40%;
    min-width: 650px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      width: 100%;
      text-align: left;
      color: #00f0f8;
      font-size: 1.5em;
      margin-bottom: 1em;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer;

      .icon {
        margin-right: 1em;
      }
    }

    p {
      width: 100%;
      margin-bottom: 1em;
    }

    .alignment-cards {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .card-option {
        width: calc(50% - 0.5em);
        height: 300px;
        border-radius: 10px;
        margin: 0 0.5em;
        cursor: pointer;
        border: 2px solid #4c516d;
        position: relative;
        overflow: hidden;

        &:hover {
          border: 2px solid #00f0f8;
          transition: all 0.2s ease-in-out;
          -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 240, 248, 1);
          -moz-box-shadow: 0px 0px 12px 0px rgba(0, 240, 248, 1);
          box-shadow: 0px 0px 12px 0px rgba(0, 240, 248, 1);

          .block-text {
            background-color: #1f2337ce;
          }
          .card-option-image {
            background-color: #6a7094;
            transform: scale(1.02);
            transition: all 0.2s ease-in-out;
            background-blend-mode: normal;
          }
        }

        .card-option-image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-color: #4c516d;
          background-blend-mode: multiply;

          &[second] {
            background-image: url("/images/subscription-img-1.jpg");
          }
          &[first] {
            background-image: url("/images/subscription-img-2.jpg");
          }
        }

        .block-text {
          position: absolute;
          width: 100%;
          padding: 2em 1em;
          bottom: 0;
          background-color: #0000003a;

          h2 {
            font-size: 1.2em;
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }

    .module {
      width: 100%;

      .container-login {
        width: 100%;
        padding: 1.2em;
        border-radius: 10px;
        background-color: #363a54;
        display: table;

        .input,
        select {
          background-color: #3f435e !important;
          border-color: #7285a5 !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1em;
    overflow-y: auto;

    .alignment {
      display: table !important;
      padding: 1em 0;
      width: 100%;
      height: 100%;
      min-width: 0px;

      .alignment-cards {
        flex-direction: column;

        .card-option {
          width: 100% !important;
          margin: 1em 0;
          height: 250px;
        }
      }
    }
  }
}
</style>
