<template>
  <div id="renew-subscription">
    <b-loading :active.sync="isLoading"></b-loading>
    <ChoosingAuth
      v-if="view === 'selection'"
      @auth-done="handlerToSubscription"
    ></ChoosingAuth>
    <Payment v-if="view === 'subscription'"></Payment>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChoosingAuth from "../components/RenewSubscription/ChoosingAuth.vue";
import Payment from "../components/RenewSubscription/Payment.vue";

export default {
  name: "RenewSubscription",
  components: {
    ChoosingAuth,
    Payment,
  },
  data() {
    return {
      view: null,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    cupom() {
      return this.$route.query.cupom ? this.$route.query.cupom : "";
    },
    isLoggedUser() {
      const userId = localStorage.getItem("userId");
      return userId;
    },
    isLoading() {
      return !this.view;
    },
  },
  methods: {
    ...mapActions(["getUser"]),
    handlerToSubscription() {
      this.view = "subscription";
    },
  },
  created() {
    if (this.isLoggedUser) {
      this.getUser(this.isLoggedUser).then(() => {
        if (this.userId) this.view = "subscription";
        else this.view = "selection";
      });
    } else {
      this.view = "selection";
    }
  },
};
</script>

<style lang="scss">
#renew-subscription {
  width: 100%;
  height: 100%;
  background-color: #25273cab;
}
</style>
